import { useEffect, useState } from "react";

// Components
import AppPageTemplate from "../../Components/AppPageTemplate/AppPageTemplate";
import Input from "../../Components/Input/Input";
import InputCalendar from "../../Components/InputCalendar/InputCalendar";
import Textarea from "../../Components/Textarea/Textarea";
import Dropdown from "../../Components/Dropdown/Dropdown";

// pop up library
import Swal from 'sweetalert2';

// db connection
import SteinStore from "stein-js-client";

//Styles
import "./NuevaCita.scss"

//Random ID
import { v4 as uuid } from "uuid";


export default function({
  title,
  cua,
  asesorData,
}) {
  //importamos Stein para la base de datos
  const AsesoresDB = new SteinStore(
    "https://api.steinhq.com/v1/storages/66233ea04a642363121e1f95"
  );
  const CitasDB = new SteinStore(
    "https://api.steinhq.com/v1/storages/66233ea04a642363121e1f95"
  );




  //campos de la nueva cita
  const fecha = new Date()
  const [place, setPlace] = useState(null)
  const [clientName, setClientName] = useState("")
  const [clientPhone, setClientPhone] = useState("")
  const [focus, setFocus] = useState(null)
  const [fechaSegundaCita, setFechaSegundaCita] = useState("")
  const [presupuesto, setPresupuesto] = useState("")
  const [porcentage, setPorcentage] = useState(null)
  const [product, setProduct] = useState(null)
  const [comments, setComments] = useState("")


  //condicionales para funcionamiento de enviar formulario
  const [enviarformulario, setEnviarFormulario] = useState(false) // 1
  const [enviandoformulario, setEnviandoFormulario] = useState(false) // 2
  





  //Dropdown options
  const PlaceOptions = [
    {key: 0, name: "Físico"},
    {key: 1, name: "Zoom"},
  ]
  const FocusOptions = [
    {key: 0, name: "Vida"},
    {key: 1, name: "GMM"},
    {key: 2, name: "Auto"},
    {key: 3, name: "Hogar"},
    {key: 4, name: "Mascota"},
  ]
  const PorcentageOptions = [
    {key: 1, name: "25%"},
    {key: 2, name: "50%"},
    {key: 3, name: "75%"},
    {key: 4, name: "100%"},
  ]
  const ProductOptions = [
    {key: 1, name: "Privilegio"}, // VIDA
    {key: 2, name: "Platino"}, // VIDA
    {key: 3, name: "Dotal"}, // VIDA
    {key: 4, name: "Trasciende"}, // VIDA
    {key: 5, name: "Vision Plus"}, // VIDA
    {key: 6, name: "Profesional"}, // VIDA
    {key: 7, name: "Elige"}, // VIDA
    {key: 8, name: "Consolida PPR"}, // VIDA
    {key: 9, name: "Consolida Total"}, // VIDA
    {key: 10, name: "Proyecta"}, // VIDA
    {key: 11, name: "Proyecta Afectó"}, // VIDA
  ]
  






  // funciones
  function fechaString(fecha) {
    const fechaObj = new Date(fecha);
    const opciones = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const fechaFormateada = fechaObj.toLocaleDateString('es-ES', opciones);
    return fechaFormateada;
  }
  // función de enviar formulario a base de datos
  const handleEnviarFormulario = (e) => {
    setEnviandoFormulario(true)
  }
  







  //useEffects
  useEffect(() => {
    if (
      place &&
      clientName &&
      clientPhone &&
      focus &&
      presupuesto &&
      porcentage &&
      product
    ) {
      setEnviarFormulario(true)
    } else {
      setEnviarFormulario(false)
      // console.log("check values:",[
      // cua,
      // asesorName,
      // asesorPhone,
      // asesorEmail,
      // fecha,
      // place,
      // clientName,
      // clientPhone,
      // focus,
      // fechaSegundaCita,
      // presupuesto,
      // porcentage,
      // product,
      // comments,
      // ]);
    }
  }, [
    fecha,
    place,
    clientName,
    clientPhone,
    focus,
    fechaSegundaCita,
    presupuesto,
    porcentage,
    product,
    comments
  ])







  useEffect(() => {
    if(enviandoformulario) {
      const loadingSwal = Swal.fire({
        title: 'Enviando registro...',
        text: 'Por favor espere un momento',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })

      
      
      

      const RegistroDeCita = {
        "uuid":  `${uuid()}`,
        "cua":  asesorData?.asesorCua,
        "uuid_asesor":  asesorData?.uuid,
        "nombre_asesor":  asesorData?.asesorName,
        "telefono_asesor": asesorData?.asesorPhone,
        "correo_asesor": asesorData?.asesorEmail,
        "estatus": "Prospecto", //Estatus por defecto de un nuevo registro
        "fecha": fechaString(fecha),
        "lugar": place ? place.name : "",
        "nombre_cliente": clientName,
        "telefono_cliente": clientPhone,
        "enfoque": focus ? focus.name : "",
        "fecha_segunda_cita": fechaSegundaCita ? fechaString(fechaSegundaCita) : "",
        "presupuesto": presupuesto,
        "porcentaje_de_cierre": porcentage ? porcentage.name : "",
        "producto": product ? product.name : "",
        "comentarios": comments
      }
      


      
      
      AsesoresDB
        .append("Citas-CRM-Valoret", [RegistroDeCita], {
          authentication: {
            username: "crmValoret68709132870961326709132", password: "3480912431264312687364870129687134268701342609741230909431206490010289764312"
          }
        })
        .then(res => {
          loadingSwal.close()
          Swal.fire({
            title: 'Registro enviado con éxito',
            icon: 'success',
            showConfirmButton: false,
            showCloseButton: false,
            iconColor: "#0F70D4",
            //borra el botón de ver registros cuando terminemos el panel de gerentes
            // <a href="https://docs.google.com/spreadsheets/d/1z8bvyLJ9HUBjqlp4LaFtCanCbbqj2LrZvWlEdetVIx4/edit?usp=sharing" class="btn">Ver registros</a>
            html: `
              <div class="afterPost">
                <a href="." class="btn">Enviar otro</a>
              </div>
            `
          })
        })
        .catch(error => {
          console.error(error)
          setEnviandoFormulario(false)
        });
    }
  }, [enviandoformulario])







  
  return (<>
    <AppPageTemplate title={title}>
      <h2 className="h2">* Lugar</h2>
      <div style={{height: "10px"}} />
      <Dropdown
        options={PlaceOptions}
        optionLabel={"name"}
        value={place}
        onChange={e => setPlace(e.value)}
      />
      <div style={{height: "30px"}} />






      <h2 className="h2">* Nombre</h2>
      <div style={{height: "10px"}} />
      <Input 
        type={"text"}
        value={clientName}
        onChange={e => setClientName(e.target.value)}
      />
      <div style={{height: "30px"}} />




      





      <h2 className="h2">* Teléfono</h2>
      <div style={{height: "10px"}} />
      <Input 
        type={"number"}      
        pattern="[+]?[0-9]*"
        value={clientPhone}
        onChange={e => setClientPhone((v) => (e.target.validity.valid ? e.target.value : v))}
      />
      <div style={{height: "30px"}} />








      <h2 className="h2">* Enfoque</h2>
      <div style={{height: "10px"}} />
      <Dropdown 
        options={FocusOptions}
        optionLabel={"name"}
        value={focus}
        onChange={e => setFocus(e.value)}
      />
      <div style={{height: "30px"}} />

      
      
      
      
      
      
      
      <h2 className="h2">Segunda cita</h2>
      <div style={{height: "10px"}} />
      <InputCalendar
        value={fechaSegundaCita} 
        onChange={e=>setFechaSegundaCita(e.value)}
      />
      <div style={{height: "30px"}} />









      <h2 className="h2">* Presupuesto</h2>
      <div style={{height: "10px"}} />
      <Input 
        type={"number"}      
        pattern="[+]?[0-9]*"
        value={presupuesto}
        onChange={e => setPresupuesto((v) => (e.target.validity.valid ? e.target.value : v))}
      />
      <div style={{height: "30px"}} />

      





      <h2 className="h2">* % de cierre</h2>
      <div style={{height: "10px"}} />
      <Dropdown 
        options={PorcentageOptions}
        optionLabel={"name"}
        value={porcentage}
        onChange={e => setPorcentage(e.value)}
      />
      <div style={{height: "30px"}} />
      









      <h2 className="h2">* Producto</h2>
      <div style={{height: "10px"}} />
      <Dropdown
        options={ProductOptions}
        optionLabel={"name"}
        value={product}
        onChange={e => setProduct(e.value)}
      />
      <div style={{height: "30px"}} />













      <h3 className="h3">Comentarios</h3>
      <div style={{height: "10px"}} />
      <Textarea
        value={comments}
        onChange={e=>setComments(e.target.value)}
      />





    </AppPageTemplate>

    {
      enviarformulario ?
      <button className="PiButton" onClick={(e) => handleEnviarFormulario(e)}><i className="pi pi-send"></i></button>
      :
      <button className="PiButton PiButtonDisabled"><i className="pi pi-send"></i></button>
    }
  </>);
}
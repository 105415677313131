import { Calendar } from 'primereact/calendar';

import "./InputCalendar.scss"

const InputCalendar = ({value, onChange}) => {
    
    return(<div className='inputCalendar'>
        <Calendar value={value} onChange={onChange} dateFormat="dd/mm/yy" />
    </div>)
}

export default InputCalendar